export const mainWebsites = [
  {
    title: "NextGen Media AI - Acquired SaaS Platform",
    description:
      "Led development of an AI-powered content generation platform, resulting in a 200% boost in video production efficiency and a successful acquisition at 9x revenue multiple.",
    imageUrl:
      "https://ik.imagekit.io/mbhxghyf1m2/NG_SQUARE_f01okiYlY.png?updatedAt=1696625991207",
    websiteUrl:
      "https://www.canva.com/design/DAFxLj0AKvA/7CiBZNZ695omDBPJcqs9xw/view?utm_content=DAFxLj0AKvA&utm_campaign=share_your_design&utm_medium=link&utm_source=shareyourdesignpanel",
  },
  {
    title: "AI-Powered Music & Media Platform",
    description:
      "Developed and launched digital media products resulting in over 48M music streams, 1.9M video views, and a 230% average monthly ad revenue increase.",
    imageUrl:
      "https://ik.imagekit.io/mbhxghyf1m2/s%20(4)_c8Hu-miqi.png?updatedAt=1718157349300",
    websiteUrl: "https://www.aiaapp.com/",
  },
  {
    title: "AI-Driven Video Segmentation Product Demo",
    description:
      "Successfully integrated and launched a product demo, resulting in a 260% increase in target user video viewership and over 2,000 alpha user sign-ups.",
    imageUrl:
      "https://ik.imagekit.io/mbhxghyf1m2/454_pbNhaZJSh.png?updatedAt=1698154790836",
    websiteUrl: "https://www.appfactoryai.com/",
  },
  {
    title: "Agora Metaverse Concert Platform",
    description:
      "Developed and launched a metaverse concert platform using Unreal Engine, achieving 1.2M views and 25,500 watch hours.",
    imageUrl:
      "https://ik.imagekit.io/mbhxghyf1m2/test%20-%20Presentation%20(1920%20x%201920%20px)%20(3)_N40FTGwgm.png?updatedAt=1697302607587",
    websiteUrl:
      "https://www.canva.com/design/DAFxQe_dDys/L9vvjTOIJgIUgbf_XDs_bg/view?utm_content=DAFxQe_dDys&utm_campaign=share_your_design&utm_medium=link&utm_source=shareyourdesignpanel",
  },
  // Consider removing or replacing the "Meeting" entry with a more relevant project
];

export const caseStudies = [
  {
    title: "Driving Engagement with AI-Powered Content Generation",
    description:
      "Leveraged GPT-4, ElevenLabs, and Stable Diffusion to create a SaaS platform that boosted video production efficiency by over 200% and resulted in a successful acquisition.",
    imageUrl:
      "https://ik.imagekit.io/mbhxghyf1m2/Untitled%20design%20(30)_z0Ajf82Wb.png?updatedAt=1708560480612",
    websiteUrl: "https://docsend.com/view/i5g7wzwakgzzyzyp",
  },
  {
    title: "Optimizing Video Viewership with AI-Driven Segmentation",
    description:
      "Integrated an AI video segmentation product that increased target user video viewership by 260% and generated over 250,000 organic impressions.",
    imageUrl:
      "https://ik.imagekit.io/mbhxghyf1m2/Mattel_(2019).svg%20(1)_btQEy924J.png?updatedAt=1698943300857",
    websiteUrl: "https://docsend.com/view/puiypzxtwiq3gwfw",
  },
  {
    title: "Building a Scalable Metaverse Concert Platform",
    description:
      "Developed and launched a metaverse concert platform using Unreal Engine, achieving 1.2M views and 25,500 watch hours.",
    imageUrl:
      "https://ik.imagekit.io/mbhxghyf1m2/Minimalist%20Black%20and%20White%20Clothing%20Store%20Logo%20(1)_rjGnLGhjZ.png?updatedAt=1699544227836",
    websiteUrl: "https://docsend.com/view/2uyqetef633xbqfk",
  },
  // Consider adding more diverse case studies showcasing different projects and skills
];

export const skills = [
  // Programming Languages
  "JavaScript",
  "Python",
  "SQL",

  // Frameworks & Libraries
  "React",

  // Cloud Technologies
  "AWS",
  "Google Cloud",

  // Design & Prototyping
  "Figma",

  // Version Control
  "Git/Github",

  // Software Development Methodologies
  "Agile Development",

  // Game Development
  "Unreal Engine",

  // Data Analysis & Visualization
  "Tableau",

  // APIs & Data Exchange
  "REST APIs",

  // Collaboration & Project Management
  "Confluence",
  "Jira",
  "Product Roadmapping",
  "User Stories",
  "Cross-Functional Collaboration",
];

export const experience = [
  {
    title: "Software Engineer || Product Manager",
    company: "AIA",
    period: "2023 - Present",
    highlights: [
      "Developed and launched digital media products across multiple AI artist channels, resulting in over 48M music streams and 1.9M video views",
      "Achieved a 230% average monthly increase in ad revenue through strategic product optimization",
      "Engineered a full-stack virtual AI artist application using AWS microservices, enabling scalable content production and distribution",
    ],
    websiteUrl: "https://www.aiaapp.com/index_investor",
  },
  {
    title: "Founder || Software Engineer",
    company: "NextGen Media AI (Acquired)",
    period: "July 2023 – 2024",
    highlights: [
      "Led the development of an AI-driven content generation SaaS platform, integrating GPT-4, ElevenLabs, and Stable Diffusion",
      "Achieved a 200% boost in video production efficiency through innovative AI solutions",
      "Secured a successful acquisition at 9x revenue multiple, demonstrating strong market positioning and product value",
    ],
    websiteUrl:
      "https://www.canva.com/design/DAFxLj0AKvA/7CiBZNZ695omDBPJcqs9xw/view?utm_content=DAFxLj0AKvA&utm_campaign=share_your_design&utm_medium=link&utm_source=shareyourdesignpanel",
  },
  {
    title: "Manager (Paris Hilton)",
    company: "YMU",
    period: "July 2022 – Present",
    highlights: [
      "Managed high-profile events, including Paris Hilton's NYE performance on NBC and debut live concert, generating millions of impressions",
      "Successfully launched several of Paris Hilton's music tracks, contributing to significant audience growth",
      "Increased Paris Hilton's Spotify monthly listeners from 710,000 to over 2 million through strategic music releases and promotions",
    ],
    websiteUrl:
      "https://www.canva.com/design/DAFxQe_dDys/L9vvjTOIJgIUgbf_XDs_bg/view?utm_content=DAFxQe_dDys&utm_campaign=share_your_design&utm_medium=link&utm_source=shareyourdesignpanel",
  },
  {
    title: "Founding Team",
    company: "LightTwist (Y Combinator 21)",
    period: "2021 – 2022",
    highlights: [
      "Integrated a cloud-native AI video segmentation product, resulting in a 260% increase in target user video viewership",
      "Led the launch of compelling product demos, generating over 250,000 organic impressions",
      "Acquired over 2,000 alpha user sign-ups, showcasing expertise in product marketing and user acquisition",
    ],
  },
  {
    title: "Product Manager",
    company: "SYB Creative",
    period: "2019 - 2021",
    highlights: [
      "Developed and launched the Agora Metaverse Concert Platform using Unreal Engine, achieving 1.2M views and 25,500 watch hours",
      "Spearheaded the agile launch of over 70 digital products, focusing on targeted feature development and user engagement",
      "Drove user growth resulting in 11M streams, demonstrating a strong understanding of agile methodologies and user-centric design",
    ],
  },
  {
    title: "Associate",
    company: "William Morris Endeavor (WME)",
    period: "2018 - 2019",
    highlights: [
      "Managed contracts valued at over $1 million for premier artists, including Bruno Mars, Drake, and Calvin Harris",
      "Collaborated with agents and management teams to ensure alignment with business objectives",
      "Demonstrated strong negotiation and communication skills in high-stakes entertainment industry dealings",
    ],
  },
];

export const education = [
  {
    degree: "BA in History, Politics Minor",
    institution: "Occidental College",
  },
  {
    degree: "AWS Certified Solutions Architect",
    institution: "Amazon Web Services",
  },
  {
    degree: "Entrepreneurship",
    institution: "The Wharton School",
  },
  {
    degree: "Founder University",
    institution: "LAUNCH",
  },
];
