import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Typography,
  Button,
  Chip,
  Box,
  AppBar,
  Toolbar,
  useScrollTrigger,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
} from "@mui/material";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from "@mui/lab";
import {
  Work,
  School,
  LinkedIn,
  Email,
  GitHub,
  FiberManualRecord,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { keyframes } from "@emotion/react";
import AOS from "aos";
import Experience from "./Experience";
import "aos/dist/aos.css";

// Import your data here
import {
  mainWebsites,
  caseStudies,
  skills,
  experience,
  education,
} from "./data";

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const MainContainer = styled("div")({
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  background: "#f5f5f5",
  color: "#333",
});

const SectionContainer = styled("section")(({ theme }) => ({
  padding: theme.spacing(10, 0),
  animation: `${fadeIn} 0.5s ease-in`,
}));

const SkillChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
  fontSize: "0.9rem",
  fontWeight: 500,
  backgroundColor: theme.palette.primary.main,
  color: "#ffffff",
  transition: "all 0.3s ease",
  "&:hover": {
    transform: "scale(1.05)",
    backgroundColor: theme.palette.primary.dark,
  },
}));

const ProjectCard = styled(Box)(({ theme }) => ({
  backgroundColor: "#ffffff",
  borderRadius: "12px",
  overflow: "hidden",
  transition: "all 0.5s ease-in-out",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
  "&:hover": {
    transform: "translateY(-10px)",
    boxShadow: "0 20px 40px rgba(0,0,0,0.2)",
  },
}));

const CardImage = styled("img")({
  width: "100%",
  aspectRatio: "1 / 1",
  objectFit: "cover",
});

const CardContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
}));

const Header = styled(AppBar)(({ theme }) => ({
  background: "transparent",
  boxShadow: "none",
  padding: theme.spacing(2, 0),
  transition: "background-color 0.3s ease-in-out",
}));

const Footer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  padding: theme.spacing(4, 0),
  marginTop: "auto",
  color: "#ffffff",
}));

const HeroSection = styled(Box)(({ theme }) => ({
  minHeight: "100vh",
  display: "flex",
  alignItems: "center",
  position: "relative",
  overflow: "hidden",
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1,
  },
}));

const BackgroundVideo = styled("video")({
  position: "absolute",
  top: "50%",
  left: "50%",
  minWidth: "100%",
  minHeight: "100%",
  width: "auto",
  height: "auto",
  transform: "translateX(-50%) translateY(-50%)",
  zIndex: 0,
});

const HeroContent = styled(Box)(({ theme }) => ({
  position: "relative",
  zIndex: 2,
  color: "#ffffff",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  textAlign: "center",
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
    textAlign: "left",
    justifyContent: "space-between",
  },
}));

const ProfileImage = styled("img")(({ theme }) => ({
  width: "200px",
  height: "200px",
  borderRadius: "50%",
  border: "4px solid #ffffff",
  boxShadow: "0 4px 20px rgba(0,0,0,0.2)",
  marginBottom: theme.spacing(4),
  [theme.breakpoints.up("md")]: {
    width: "300px",
    height: "300px",
    marginBottom: 0,
  },
}));

function ElevationScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    style: {
      backgroundColor: trigger ? "rgba(98, 0, 234, 0.9)" : "transparent",
      boxShadow: trigger ? "0 4px 20px rgba(0, 0, 0, 0.1)" : "none",
    },
  });
}

function LandingPage() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
      mirror: false,
    });
  }, []);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <MainContainer>
      <ElevationScroll>
        <Header position="fixed">
          <Toolbar>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, fontWeight: 600, color: "#ffffff" }}
            >
              James Coholan
            </Typography>
            <Button
              color="inherit"
              href="#contact"
              sx={{
                display: { xs: "none", sm: "block" },
              }}
            >
              Contact
            </Button>
            <Button
              color="inherit"
              onClick={handleMenuToggle}
              sx={{
                display: { xs: "block", sm: "none" },
              }}
            >
              Menu
            </Button>
          </Toolbar>
        </Header>
      </ElevationScroll>

      {/* Hero Section */}
      <HeroSection>
        <BackgroundVideo autoPlay loop muted>
          <source
            src="https://sybassets.s3.us-west-1.amazonaws.com/Blue+Particles+Luxury+Awards+Background%2C+Particle+zoom+Led+Light+wall+VJ+Loop+motion+background+New.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </BackgroundVideo>
        <Container maxWidth="lg">
          <HeroContent>
            <Box sx={{ flex: 1, mb: { xs: 4, md: 0 } }}>
              <Typography
                variant="h1"
                component="h1"
                gutterBottom
                sx={{
                  fontWeight: "bold",
                  fontSize: { xs: "2.5rem", sm: "3rem", md: "4.5rem" },
                  marginBottom: 4,
                }}
                data-aos="fade-right"
              >
                James Coholan
              </Typography>
              <Typography
                variant="h4"
                component="h2"
                gutterBottom
                sx={{
                  marginBottom: 4,
                  fontSize: { xs: "1.5rem", sm: "2rem", md: "2.5rem" },
                }}
                data-aos="fade-right"
                data-aos-delay="200"
              >
                Tech Leader & Innovator
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{
                  fontSize: { xs: "1rem", md: "1.2rem" },
                  maxWidth: "600px",
                  marginBottom: 4,
                }}
                data-aos="fade-right"
                data-aos-delay="400"
              >
                Driving digital transformation and innovation in the tech
                industry. Passionate about leveraging AI and emerging
                technologies to solve complex business challenges.
              </Typography>
              <Grid
                container
                spacing={2}
                sx={{
                  marginBottom: 4,
                  justifyContent: { xs: "center", md: "flex-start" },
                }}
                data-aos="fade-up"
                data-aos-delay="600"
              >
                <Grid item>
                  <Button
                    variant="contained"
                    startIcon={<LinkedIn />}
                    href="https://www.linkedin.com/in/james-coholan/"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      backgroundColor: "#ffffff",
                      color: "#6200ea",
                      "&:hover": { backgroundColor: "#e0e0e0" },
                    }}
                  >
                    LinkedIn
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    startIcon={<GitHub />}
                    href="https://github.com/jamescoholan"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      color: "#ffffff",
                      borderColor: "#ffffff",
                      "&:hover": {
                        borderColor: "#e0e0e0",
                        backgroundColor: "rgba(255,255,255,0.1)",
                      },
                    }}
                  >
                    GitHub
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    startIcon={<Email />}
                    href="mailto:JamesCoholan@gmail.com"
                    sx={{
                      color: "#ffffff",
                      borderColor: "#ffffff",
                      "&:hover": {
                        borderColor: "#e0e0e0",
                        backgroundColor: "rgba(255,255,255,0.1)",
                      },
                    }}
                  >
                    Email
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                flex: { xs: 1, md: 0.8 },
                display: "flex",
                justifyContent: "center",
              }}
            >
              <ProfileImage
                src="https://ik.imagekit.io/mbhxghyf1m2/jc_ztTQ_bFZ_.png?updatedAt=1698875476657"
                alt="James Coholan"
                data-aos="fade-left"
              />
            </Box>
          </HeroContent>
        </Container>
      </HeroSection>

      {/* Experience Section */}
      <SectionContainer id="experience">
        <Experience experience={experience} />
      </SectionContainer>

      {/* Experience Section
      <SectionContainer id="experience">
        <Container maxWidth="md">
          <Typography
            variant="h3"
            component="h2"
            gutterBottom
            align="center"
            sx={{ mb: 6, fontWeight: 600, color: "#333" }}
            data-aos="fade-up"
          >
            Professional Experience
          </Typography>
          <Timeline position="alternate">
            {(experience || []).map((job, index) => (
              <TimelineItem
                key={index}
                data-aos={index % 2 === 0 ? "fade-right" : "fade-left"}
              >
                <TimelineSeparator>
                  <TimelineDot sx={{ backgroundColor: "#6200ea" }}>
                    <Work />
                  </TimelineDot>
                  <TimelineConnector sx={{ backgroundColor: "#6200ea" }} />
                </TimelineSeparator>
                <TimelineContent>
                  <Typography
                    variant="h6"
                    component="h3"
                    sx={{ fontWeight: "bold", color: "#000", mb: 1 }}
                  >
                    {job.title || "Position"}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    sx={{ color: "#000", fontWeight: 500, mb: 0.5 }}
                  >
                    {job.company || "Company"}
                  </Typography>
                  <Typography variant="subtitle2" sx={{ color: "#555", mb: 2 }}>
                    {job.period || "Date"}
                  </Typography>
                  <List dense disablePadding>
                    {(
                      job.highlights || [
                        "Responsibility 1",
                        "Responsibility 2",
                        "Responsibility 3",
                      ]
                    ).map((highlight, i) => (
                      <ListItem
                        key={i}
                        disablePadding
                        sx={{ mb: 1 }}
                        data-aos="fade-up"
                        data-aos-delay={i * 100}
                      >
                        <ListItemIcon sx={{ minWidth: "24px" }}>
                          <FiberManualRecord
                            sx={{ fontSize: 8, color: "#6200ea" }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={highlight}
                          primaryTypographyProps={{
                            variant: "body2",
                            color: "#000",
                            sx: {
                              fontWeight: 400,
                              lineHeight: 1.5,
                            },
                          }}
                        />
                      </ListItem>
                    ))}
                  </List>
                </TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
        </Container>
      </SectionContainer> */}

      {/* Skills Section */}
      <SectionContainer id="skills" sx={{ backgroundColor: "#f0f0f0" }}>
        <Container maxWidth="md">
          <Typography
            variant="h3"
            component="h2"
            gutterBottom
            align="center"
            sx={{ mb: 6, fontWeight: 600, color: "#333" }}
            data-aos="fade-up"
          >
            Skills
          </Typography>
          <Grid container spacing={2} justifyContent="center">
            {(skills || []).map((skill, index) => (
              <Grid
                item
                key={index}
                data-aos="zoom-in"
                data-aos-delay={index * 50}
              >
                <SkillChip label={skill} />
              </Grid>
            ))}
          </Grid>
        </Container>
      </SectionContainer>

      {/* Main Projects Section */}
      <SectionContainer id="projects">
        <Container maxWidth="lg">
          <Typography
            variant="h3"
            component="h2"
            gutterBottom
            align="center"
            sx={{ mb: 6, fontWeight: 600, color: "#333" }}
            data-aos="fade-up"
          >
            Featured Projects
          </Typography>
          <Grid container spacing={4}>
            {(mainWebsites || []).map((website, index) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                key={index}
                data-aos="fade-up"
                data-aos-delay={index * 100}
              >
                <ProjectCard>
                  <CardImage
                    src={website.imageUrl || "https://via.placeholder.com/300"}
                    alt={website.title || "Project"}
                  />
                  <CardContent>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{ color: "#333", fontWeight: 600 }}
                    >
                      {website.title || "Project Title"}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" paragraph>
                      {website.description || "Project description"}
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      href={website.websiteUrl || "#"}
                      target="_blank"
                      rel="noopener noreferrer"
                      size="small"
                    >
                      View Project
                    </Button>
                  </CardContent>
                </ProjectCard>
              </Grid>
            ))}
          </Grid>
        </Container>
      </SectionContainer>

      {/* Case Studies Section */}
      <SectionContainer id="case-studies" sx={{ backgroundColor: "#f0f0f0" }}>
        <Container maxWidth="lg">
          <Typography
            variant="h3"
            component="h2"
            gutterBottom
            align="center"
            sx={{ mb: 6, fontWeight: 600, color: "#333" }}
            data-aos="fade-up"
          >
            Case Studies
          </Typography>
          <Grid container spacing={4}>
            {(caseStudies || []).map((study, index) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                key={index}
                data-aos="fade-up"
                data-aos-delay={index * 100}
              >
                <ProjectCard>
                  <CardImage
                    src={study.imageUrl || "https://via.placeholder.com/300"}
                    alt={study.title || "Case Study"}
                  />
                  <CardContent>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{ color: "#333", fontWeight: 600 }}
                    >
                      {study.title || "Case Study Title"}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" paragraph>
                      {study.description || "Case study description"}
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      href={study.websiteUrl || "#"}
                      target="_blank"
                      rel="noopener noreferrer"
                      size="small"
                    >
                      View Case Study
                    </Button>
                  </CardContent>
                </ProjectCard>
              </Grid>
            ))}
          </Grid>
        </Container>
      </SectionContainer>

      {/* Education Section */}
      <SectionContainer id="education">
        <Container maxWidth="md">
          <Typography
            variant="h3"
            component="h2"
            gutterBottom
            align="center"
            sx={{ mb: 6, fontWeight: 600, color: "#333" }}
            data-aos="fade-up"
          >
            Educational Background
          </Typography>
          <Timeline position="alternate">
            {(education || []).map((edu, index) => (
              <TimelineItem
                key={index}
                data-aos={index % 2 === 0 ? "fade-right" : "fade-left"}
              >
                <TimelineSeparator>
                  <TimelineDot sx={{ backgroundColor: "#6200ea" }}>
                    <School />
                  </TimelineDot>
                  <TimelineConnector sx={{ backgroundColor: "#6200ea" }} />
                </TimelineSeparator>
                <TimelineContent>
                  <Typography
                    variant="h6"
                    component="h3"
                    sx={{ fontWeight: "bold", color: "#333" }}
                  >
                    {edu.degree || "Degree"}
                  </Typography>
                  <Typography color="textSecondary">
                    {edu.institution || "Institution"}
                  </Typography>
                </TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
        </Container>
      </SectionContainer>

      {/* Contact Section */}
      <SectionContainer
        id="contact"
        sx={{ backgroundColor: "#6200ea", color: "#ffffff" }}
      >
        <Container maxWidth="md">
          <Typography
            variant="h3"
            component="h2"
            gutterBottom
            align="center"
            sx={{ mb: 4, fontWeight: 600 }}
            data-aos="fade-up"
          >
            Let's Connect
          </Typography>
          <Typography
            variant="body1"
            paragraph
            align="center"
            sx={{ mb: 4 }}
            data-aos="fade-up"
            data-aos-delay="200"
          >
            Interested in collaborating or learning more about my work? Feel
            free to reach out!
          </Typography>
          <Grid
            container
            justifyContent="center"
            spacing={3}
            data-aos="fade-up"
            data-aos-delay="400"
          >
            <Grid item>
              <Button
                variant="contained"
                startIcon={<Email />}
                href="mailto:james@example.com"
                sx={{
                  py: 1.5,
                  px: 3,
                  backgroundColor: "#ffffff",
                  color: "#6200ea",
                  "&:hover": {
                    backgroundColor: "#e0e0e0",
                  },
                }}
              >
                Email Me
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                startIcon={<LinkedIn />}
                href="https://www.linkedin.com/in/james-coholan/"
                sx={{
                  py: 1.5,
                  px: 3,
                  color: "#ffffff",
                  borderColor: "#ffffff",
                  "&:hover": {
                    borderColor: "#e0e0e0",
                    backgroundColor: "rgba(255,255,255,0.1)",
                  },
                }}
              >
                LinkedIn
              </Button>
            </Grid>
          </Grid>
        </Container>
      </SectionContainer>

      <Footer>
        <Container maxWidth="lg">
          <Typography variant="body2" align="center">
            © {new Date().getFullYear()} James Coholan. All rights reserved.
          </Typography>
        </Container>
      </Footer>
    </MainContainer>
  );
}

export default LandingPage;
