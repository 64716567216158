import React from "react";
import { AppBar, Toolbar, Box, useTheme } from "@mui/material";
import AppFactoryLogo from "../Assets/34.png"; // Adjust the path accordingly

function Header() {
  const theme = useTheme();

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        backdropFilter: "blur(10px)",
        borderBottom: "1px solid white", // Changed the border color to white
        boxShadow: "0 2px 10px rgba(225, 82, 185, 0.3)", // You can adjust this as well if needed
      }}
    >
      <Toolbar style={{ justifyContent: "center", padding: 0 }}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            "& img": {
              height: {
                xs: "40px", // smaller size for extra small screens
                sm: "50px", // default size for small screens and above
              },
              marginRight: "10px",
            },
          }}
        >
          {/* <img src={AppFactoryLogo} alt="James Coholan Logo" /> */}
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
