import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
} from "@mui/material";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from "@mui/lab";
import { Work, FiberManualRecord } from "@mui/icons-material";
import { styled } from "@mui/material/styles";

const SectionContainer = styled("section")(({ theme }) => ({
  padding: theme.spacing(10, 0),
}));

const ExperienceItem = styled(Box)(({ theme }) => ({
  cursor: "pointer",
  transition: "all 0.3s ease-in-out",
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
    transform: "translateY(-5px)",
  },
}));

const WebViewContainer = styled(Paper)(({ theme }) => ({
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: 1000,
  width: 800,
  height: 600,
  overflow: "hidden",
  boxShadow: theme.shadows[10],
}));

const WebViewFrame = styled("iframe")({
  width: "100%",
  height: "100%",
  border: "none",
});

function Experience({ experience }) {
  const [hoveredJob, setHoveredJob] = useState(null);

  useEffect(() => {
    console.log("Experience data:", experience);
  }, [experience]);

  const handleMouseEnter = (job) => {
    console.log("Mouse enter:", job.title, "Website URL:", job.websiteUrl);
    setHoveredJob(job);
  };

  const handleMouseLeave = () => {
    console.log("Mouse leave");
    setHoveredJob(null);
  };

  return (
    <SectionContainer id="experience">
      <Container maxWidth="md">
        <Typography
          variant="h3"
          component="h2"
          gutterBottom
          align="center"
          sx={{ mb: 6, fontWeight: 600, color: "#333" }}
          data-aos="fade-up"
        >
          Professional Experience
        </Typography>
        <Timeline position="alternate">
          {experience.map((job, index) => (
            <TimelineItem
              key={index}
              data-aos={index % 2 === 0 ? "fade-right" : "fade-left"}
            >
              <TimelineSeparator>
                <TimelineDot sx={{ backgroundColor: "#6200ea" }}>
                  <Work />
                </TimelineDot>
                <TimelineConnector sx={{ backgroundColor: "#6200ea" }} />
              </TimelineSeparator>
              <TimelineContent>
                <ExperienceItem
                  onMouseEnter={() => handleMouseEnter(job)}
                  onMouseLeave={handleMouseLeave}
                >
                  <Typography
                    variant="h6"
                    component="h3"
                    sx={{ fontWeight: "bold", color: "#000", mb: 1 }}
                  >
                    {job.title || "Position"}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    sx={{ color: "#000", fontWeight: 500, mb: 0.5 }}
                  >
                    {job.company || "Company"}
                  </Typography>
                  <Typography variant="subtitle2" sx={{ color: "#555", mb: 2 }}>
                    {job.period || "Date"}
                  </Typography>
                  <List dense disablePadding>
                    {(
                      job.highlights || [
                        "Responsibility 1",
                        "Responsibility 2",
                        "Responsibility 3",
                      ]
                    ).map((highlight, i) => (
                      <ListItem
                        key={i}
                        disablePadding
                        sx={{ mb: 1 }}
                        data-aos="fade-up"
                        data-aos-delay={i * 100}
                      >
                        <ListItemIcon sx={{ minWidth: "24px" }}>
                          <FiberManualRecord
                            sx={{ fontSize: 8, color: "#6200ea" }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={highlight}
                          primaryTypographyProps={{
                            variant: "body2",
                            color: "#000",
                            sx: {
                              fontWeight: 400,
                              lineHeight: 1.5,
                            },
                          }}
                        />
                      </ListItem>
                    ))}
                  </List>
                  {job.websiteUrl && (
                    <Button
                      variant="outlined"
                      color="primary"
                      href={job.websiteUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{ mt: 2 }}
                    >
                      View Project
                    </Button>
                  )}
                </ExperienceItem>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </Container>
      {hoveredJob && hoveredJob.websiteUrl && (
        <WebViewContainer>
          <Typography variant="h6" gutterBottom>
            Web View for {hoveredJob.title}
          </Typography>
          <WebViewFrame
            src={hoveredJob.websiteUrl}
            title={hoveredJob.company}
          />
        </WebViewContainer>
      )}
    </SectionContainer>
  );
}

export default Experience;
