import React from "react";
import logo from "../Assets/13.png";

function LogoDisplay() {
  return (
    <div
      style={{
        display: "grid",
        placeItems: "center",
        height: "auto", // Using auto to allow it to grow with content
      }}
    >
      <img
        src={logo}
        alt="NextGen Logo"
        style={{ width: "60%" }}
        className="fade-in"
      />
    </div>
  );
}

export default LogoDisplay;
